import React, { useState, useEffect } from 'react';
import { useParams ,useLocation ,useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import axios from '../axios.js';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import Header from '../components/Header';
import Status from '../components/Status';
import Preference from '../components/Preference';
import DisclaimerModal from '../components/DisclamerModal';
import Banner from '../components/Banner';
import Essentials from '../components/Essentials';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import Map from "../components/GoogleMap.jsx";
import '../index.css';
import i18n from '../i18n';
import common from '../common.js';

const Home = () => {
  const { trackingNumber, pin, lang } = useParams();
  const navigate = useNavigate(); 
  const { t } = useTranslation();
  const {search} = useLocation();
  const [state, setState] = useState({
    order: {
      packages: [],
      preferred_date: '',
      preferred_window: [],
      visit_dates: [],
      merchant: {},
      customer: {
        address: {
          zone: {
            city: {}
          }
        }
      }
    },
    trackingType: 'delivery-order',
    showQrCode: false,
    currentLocation: {
      lat: 30.018,
      lng: 31.217
    },
    trackingNumber,
    pin:search.split("pin=")[1] || null,
    current: {},
    showDisclaimerModal: false,
    blur: true,
    qrCodeIndex: 0,
    stopLoading: true,
    notify: false,
    message: '',
    times: [],
  });
  useEffect(() => {
    if (state.pin) {
      navigate(`/${lang}/${trackingNumber}?pin=${state.pin}`)
    }
    loadOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingNumber, pin, lang]);

  const loadOrder = () => {
    let trackingType = ''
    if (trackingNumber[0] === '1' || (trackingNumber[0] === '3' && trackingNumber[1] === '1')) {
      trackingType = 'delivery-order'
    } else if (trackingNumber[0] === '2' || (trackingNumber[0] === '3' && trackingNumber[1] === '2')) {
      trackingType = 'return'
    } else if (trackingNumber[0] === '4' || trackingNumber[0] === '4') {
      trackingType = 'cash-collection'
    }
    setState((prevState) => ({ ...prevState, trackingType, stopLoading: false }));
    axios.get(`${trackingType}/${trackingNumber}/?pin=${state.pin ? state.pin : ''}`)
      .then((response) => {
        setState((prevState) => ({
          ...prevState,
          order: response.data,
          times: response.data.preferred_window,
          trackingNumber: response.data.tracking_number,
          showQrCode: response.data.packages && response.data.cash_amount === 0,
          current: {
            lat: response.data.customer.address.latitude || 30.018,
            lng: response.data.customer.address.longitude || 31.217
          },
          stopLoading: true
        }));
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          stopLoading: true,
          notify: true,
          message: common.notifyResponseError(error),
        }));
      });
  };
  const downloadQrCode = (index) => {
    const svg = document.querySelectorAll('svg.qr-code');
    if (svg.length > 0) {
      const clonedSvgElement = svg[0].cloneNode(true);
      const outerHTML = clonedSvgElement.outerHTML;
      const blob = new Blob([outerHTML], { type: 'image/svg+xml;charset=utf-8' });
      const blobURL = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobURL;
      link.download = `shipblu-${state.order.tracking_number}-${index + 1}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
 const openDisclamer =  () => {
    setState({
      ...state,
      showDisclaimerModal: true 
    })
  };
 const getDataFromChild = (showDisclaimerModal, blur) => {
    setState({
      ...state,
      blur: blur === 'removeBlur' ? false : true,
      showDisclaimerModal: false
    })
  };
  const slideQrCode = (type) => {
    setState({...state, qrCodeIndex: type === 'next' ? state.qrCodeIndex + 1 : state.qrCodeIndex - 1 })
    document.getElementsByClassName('qr-code')[0].classList.add(type === 'next' ? 'slide-right' : 'slide-left')
    setTimeout(() => {
      document.getElementsByClassName('qr-code')[0].classList.remove('slide-left')
      document.getElementsByClassName('qr-code')[0].classList.remove('slide-right')
    }, 500);
  };
  return (
    <div>
      <Header />
      <div className="m-auto" style={{ maxWidth: '1440px' }}>
        <div className="w-full" style={{ marginTop: '80px' }}>
          <div className={state.stopLoading ? '' : 'show-modal loading-window fixed inset-0 z-20'}>
            <div id="loading"></div>
          </div>
          {state.notify && (
            <div>
              <div className="alert error mx-5">
                <p className="inner">{state.message}</p>
              </div>
            </div>
          )}
          {state.errorStatus === 404 ? (
            <main-tracking errorFlag={true} />
          ) : (
            <div className="w-full lg:py-16 py-5 lg:px-16 px-5 main">
              <p className="w-full font-medium md:text-2xl text-xl">
                <span className="uppercase text-darkblue">{t('we have an eye on your shipment')}</span>
                <span className="self-center lowercase text-blue"> #{state.order.tracking_number}</span>
              </p>
              <div className="w-full mt-2">
                <p className="font-normal md:text-base text-sm text-[#404040] flex">{t('Here you can track your shipment, and reschedule your delivery date.')}</p>
              </div>
              <div className={`w-full gap-6 grid mt-8 ${state.showQrCode ? 'grid-cols-2' : 'grid-cols-1'}`}>
                {/* STATUS */}
                <div className="col-span-2">
                  <Status trackingType={state.trackingType} order={state.order} />
                </div>
                {/* PREFERNCE */}
                <div className="col-span-2">
                  <Preference 
                    trackingType={state.trackingType}
                    pin={state.pin}
                    showQrCode={state.showQrCode}
                    order={state.order}
                    field={state.field}
                    value={state.value}
                    preferenceModal={state.preferenceModal}
                    times={state.times}
                    changeOrderOpen={state.changeOrderOpen}
                    sendData={state.sendData}
                    loadOrder={loadOrder}
                    preferenceModalFlag={state.preferenceModalFlag}
                  />
                </div>
                {/* QRcode */}
                { state.order.packages && (state.order.cash_amount === 0 || state.order.is_paid === true) &&
                    <div className="card relative xl:py-7 xl:px-14 p-6 h-full lg:col-span-1 col-span-2">
                      <div className="h-full">
                        <div className="flex justify-between">
                          <p className="text-[#404040] font-medium text-xl">{ t('Packages QR codes') }</p>
                          <div>
                            <span className="text-black font-medium text-lg">{ t('Packages : ') }</span>
                            <span className="text-gray-400 font-medium text-lg">{ t( state.order.packages.length) }</span>
                          </div>
                        </div>
                        { <div className="mt-6 sm:py-10 py-8 sm:px-5 px-2 overflow-hidden border border-solid border-[#8599B3] rounded-md">
                            <div className="relative">
                              {
                                state.blur &&
                                <button className={`top-1/2 left-1/2 z-10 translate-1/2 absolute font-semibold text-xs bg-blue-600 text-white rounded-sm focus:outline-none px-4 h-8 whitespace-nowrap`} onClick={openDisclamer}>{t('Show QR Code')}</button>
                              }
                              <div className="flex justify-between h-full items-center">
                              { i18n.language === 'en' ?
                               (
                                <ChevronLeftIcon
                                style={{
                                   color: "#737373",
                                   userSelect: 'none',
                                   visibility: state.qrCodeIndex === 0 || state.blur ? 'hidden' : ''
                                   }}
                                  onClick={() => slideQrCode('prev')}
                                  />
                               )
                               :
                               (
                                <ChevronRightIcon
                                style={{
                                color: "#737373",
                                userSelect: 'none',
                                visibility: state.qrCodeIndex === state.order.packages.length - 1 || state.blur ? 'hidden' : ''
                                }}
                                onClick={() => slideQrCode('next')}
                                />
                               )}
                                <div className="relative">
                                  <QRCode
                                    className='qr-code'
                                    style={{maxWidth: "170px", height: "auto", width: "100%", filter: state.blur ? 'blur(4px)' : "blur(0px)" }}
                                    value={'OTQ: ' + state.order.packages[state.qrCodeIndex].sms_confirmation_code}
                                  />
                                </div>
                               { i18n.language === 'en' ?
                               (
                                <ChevronRightIcon
                                style={{
                                color: "#737373",
                                userSelect: 'none',
                                visibility: state.qrCodeIndex === state.order.packages.length - 1 || state.blur ? 'hidden' : ''
                                }}
                                onClick={() => slideQrCode('next')}
                                />
                               )
                               : 
                               (
                                <ChevronLeftIcon
                                style={{
                                   color: "#737373",
                                   userSelect: 'none',
                                   visibility: state.qrCodeIndex === 0 || state.blur ? 'hidden' : ''
                                   }}
                                  onClick={() => slideQrCode('prev')}
                                  />
                               )
                                
                               }
                              </div>
                            </div>
                            {
                              !state.blur &&
                              <>
                                <p className={'mt-2 text-lg text-center text-black font-medium'}>
                                  <span>{t('Package')} </span>
                                  <span>{ state.qrCodeIndex + 1 }</span>
                                </p>
                                <div className="text-center mt-2">
                                  <button className={`font-semibold text-xs bg-blue-600 text-white rounded-sm focus:outline-none px-4 h-8 min-w-44 whitespace-nowrap`} onClick={() => downloadQrCode(state.qrCodeIndex)}>{t('Save QR code')}</button>
                                </div>
                              </>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  }
                {/* Disclaimer */}
                <DisclaimerModal showDisclaimerModal={state.showDisclaimerModal} sendDataToParentDisclamer={getDataFromChild} />
                {/* Map */}
                <div className={`${state.showQrCode ? 'lg:col-span-1 col-span-2' : 'col-span-2'}`}>
                  <Map 
                    current={state.current} 
                    pin={state.pin} 
                    blur={state.blur} 
                    addressModal={state.addressModal} 
                    order={state.order} 
                    trackingType={state.trackingType} 
                    notify={state.notify} 
                    fromCreation={state.fromCreation} 
                    showErrorMessage={state.showErrorMessage} 
                    message={state.message}
                    field={state.field} 
                    value={state.value} 
                    changeOrderOpen={state.changeOrderOpen} 
                    sendData={state.sendData} 
                    address={state.address} 
                    sendDataToParent={(data) => setState((prevState) => ({ ...prevState, ...data }))}
                    loadOrder={loadOrder}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <Banner />
        <Essentials />
        <ContactUs order={state.order} />
      </div>
      <Footer />
    </div>
  );
};

export default Home;