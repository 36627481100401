import {  createRef } from 'react';
import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import common from '../common';
import i18n from '../i18n'
import axios from '../axios';
import ReCAPTCHA from "react-google-recaptcha";
import Mail from '@mui/icons-material/Mail';

class ContactUs extends Component {
  constructor (props) {
    super(props);
    this.state = {
      recaptchaRef: React.createRef(),
      name: '',
      email: '',
      subject: '',
      body: '',
      name2:'',
      errors: {},
      sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
      isFormValid: false,
      notify: false,
      message: '',
      validateWithRecaptcha: false
    }
    this.recaptcha = createRef();
  }

  validateForm = () => {
    let errors = {}; 
    if (!this.state.name) { 
      errors.name = 'Name is required.'; 
    } 
    if (!this.state.email) { 
      errors.email = 'Email is required.'; 
    } else if (!/\S+@\S+\.\S+/.test(this.state.email)) { 
      errors.email = 'Email is invalid.'; 
    } 
    if (!this.state.subject) { 
      errors.subject = 'Subject is required.'; 
    } 
    if (!this.state.body) { 
      errors.body = 'Message is required.'; 
    }
    if (!this.state.validateWithRecaptcha) { 
      errors.recaptcha = 'Recaptcha is required.'; 
    }
    this.setState({
      errors: errors,
      isFormValid: Object.keys(errors).length === 0
    })
  }
  sendEmail = (e) => {
    e.preventDefault();
    this.validateForm()
    if (this.state.isFormValid && this.state.validateWithRecaptcha) {
      const data = {
        email: this.state.email,
        subject: this.props.order && this.props.order.tracking_number ? '#' + this.props.order.tracking_number + ' ' + this.state.subject : this.state.subject,
        name: this.state.name,
        body: this.state.body
      }
      axios.post(`myshipblu/customer-support/send-help-email/`, data, {
      })
        .then(() => {
          this.state.recaptchaRef.current.reset()
          this.setState({
            name: '',
            email: '',
            subject: '',
            body: '',
            isFormValid: false,
            validateWithRecaptcha: false
          })
          this.setState({
            notify: true,
            message: 'Your email has been sent!'
          })
        })
        .catch((error) => {
          this.stopLoading = true
          this.setState({
            notify: true,
            message: common.notifyResponseError(error)
          })
        })
    } 
  };

  render () {
    const { t } = this.props;
    return (
      <div className='flex md:flex-row flex-col gap-6 md:p-10 py-10 px-4'>
        {
          this.state.notify ?
          <div>
            <div className="alert success mx-5">
              <p className="inner">{ this.state.message }</p>
            </div>
          </div> : null
        }
        <div className='bg-gray-200 md:w-96 shrink-0'>
          <div className='p-6'>
            <p className='font-bold mb-5 text-blue-600 text-xl'>{t('Contact Us')}</p>
            <p className='font-semibold text-gray-800 text-lg'>{t('Our team will reach back to you as soon as possible once you are fill up the form')}</p>
            <div className='flex items-center gap-5 mt-10'>
              <Mail className='text-blue-600' />
              <a href='mailto:help@shipblu.com'>{'help@shipblu.com'}</a>
            </div>
          </div>
          <div className='pb-6 sm:w-80 w-64'>
            <img className={`w-auto ${i18n.language === 'ar' ? 'switch' : ''}`} src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/bird.svg" width={100} height={50} alt="" />
          </div>
        </div>
        <div className='w-full grid grid-cols-2 gap-6'>
          <div className='lg:col-span-1 col-span-2'>
            <p className='text-blue-600 mb-1 text-lg font-semibold'>{t('Your Name')}</p>
            <input value={this.state.name} className='focus:outline-none border-b-2 w-full' name='from_name' onChange={(event) => this.setState({name: event.target.value}, this.validateForm)}></input>
            {this.state.errors.name && <p className='validate-input'>{this.state.errors.name}</p>} 
          </div>
          <div className='lg:col-span-1 col-span-2'>
            <p className='text-blue-600 mb-1 text-lg font-semibold'>{t('Subject')}</p>
            <div className='flex gap-2'>
              {
                this.props.order && this.props.order.tracking_number &&
                <p className='text-blue-600 border-b-2'>#{this.props.order.tracking_number}</p>
              }
              <input value={this.state.subject} className='focus:outline-none border-b-2 w-full' name="subject" onChange={(event) => this.setState({subject: event.target.value}, this.validateForm)}></input>
            </div>
            {this.state.errors.subject && <p className='validate-input'>{this.state.errors.subject}</p>} 
          </div>
          <div className='col-span-2'>
            <p className='text-blue-600 mb-1 text-lg font-semibold'>{t('Email')}</p>
            <input value={this.state.email} className='focus:outline-none border-b-2 w-full' name='email' onChange={(event) => this.setState({email: event.target.value}, this.validateForm)}></input>
            {this.state.errors.email && <p className='validate-input'>{this.state.errors.email}</p>} 
          </div>
          <div className='col-span-2'>
            <p className='text-blue-600 mb-1 text-lg font-semibold'>{t('Your Message')}</p>
            <textarea value={this.state.body} className='focus:outline-none border-b-2 w-full' name='body' onChange={(event) => this.setState({body: event.target.value}, this.validateForm)}></textarea>
            {this.state.errors.body && <p className='validate-input'>{this.state.errors.body}</p>} 
          </div>
          <div className={`col-span-2 ${i18n.language === 'en' ? 'text-right' : 'text-left'}`}>
            <ReCAPTCHA ref={this.state.recaptchaRef} className='mb-2' sitekey={this.state.sitekey} onChange={(event) => this.setState({validateWithRecaptcha: true}, this.validateForm)}/>
            {this.state.errors.recaptcha && <p className='validate-input flex'>{this.state.errors.recaptcha}</p>} 
            <button onClick={this.sendEmail}  className='bg-blue-600 py-0 rounded-md text-white px-10 font-semibold h-10'>{t('Send')}</button>
          </div>
        </div>
      </div>
    )
  }
}
export default withTranslation()(ContactUs)