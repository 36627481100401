import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

const Banner = () => {
  const { t } = useTranslation();

  return (
    <div>
      {/* // larg screen banner */}
      <div className="xl:px-24 lg:px-20 sm:px-10 px-6 lg:pb-44 pb-14 pt-36 relative sm:block hidden">
        <div className={`myBlu-bg overflow-hidden relative rounded-3xl text-white xl:px-16 lg:px-12 md:px-8 px-6 py-8 xl:py-20 lg:py-16 xl:me-24 lg:me-20 me-0 ${i18n.language === 'en' ? 'bg-en' : 'bg-ar'}`}>
          <div className={`z-10 relative ${i18n.language === 'ar'? 'content-ar' : 'content'}`}>
            <img className="xl:w-auto lg:w-36 w-24" width={8} height={8} src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/year-in-review/myblu-logo.svg" alt=""/>
            <div className="font-semibold md:text-4xl sm:text-3xl text-xl mt-4">
              <p className='sm:mb-4 mb-2'>{t('Valu installments is now available at your doorstep')}</p>
            </div>
            <div className="font-medium lg:text-2xl md:text-xl text-base leading-tight md:mt-6 mt-3">
              <p>{t('Your customers can now pay in installments for up to 60 months using Valu through myBlu app.')} </p>
              <img width={613} height={60} className="mt-8 w-auto h-auto" src="https://staging-publicstorage1123538a-1p8k71zpb6h3c.s3.eu-west-3.amazonaws.com/image+14.svg" alt=""/>
            </div>
            <div className="xl:hidden self-center md:mt-10 mt-6 flex gap-3">
              <a href="https://play.google.com/store/apps/details?id=com.shipblu.myblu" target="_blank" rel="noopener noreferrer">
                <img className="sm:w-40 w-32 max-w-full cursor-pointer" src="https://staging-publicstorage1123538a-1p8k71zpb6h3c.s3.eu-west-3.amazonaws.com/Mobile+app+store+badge%402x.svg" alt="Google Play Store" width={160} height={100000}/>
              </a>
              <a href="https://apps.apple.com/eg/app/myblu/id6469455066" target="_blank" rel="noopener noreferrer">
                <img className="sm:w-40 w-32 max-w-full cursor-pointer" src="https://staging-publicstorage1123538a-1p8k71zpb6h3c.s3.eu-west-3.amazonaws.com/Mobile+app+store+badge.svg" alt="App Store" width={160} height={10000}/>
              </a>
            </div>
          </div>
        </div>
        <div className={`${i18n.language === 'ar'? 'left-24' : 'right-24'} top-0 pt-36 lg:absolute hidden lg:flex xl:gap-4 gap-2`}>
          <div className="self-center xl:mt-48 lg:mt-40 mt-0 xl:grid hidden grid-cols-1 gap-3">
            <a href="https://play.google.com/store/apps/details?id=com.shipblu.myblu" target="_blank" rel="noopener noreferrer">
              <img className="sm:w-40 w-32 max-w-full cursor-pointer" src="https://staging-publicstorage1123538a-1p8k71zpb6h3c.s3.eu-west-3.amazonaws.com/Mobile+app+store+badge%402x.svg" alt="Google Play Store" width={160} height={100000}/>
            </a>
            <a href="https://apps.apple.com/eg/app/myblu/id6469455066" target="_blank" rel="noopener noreferrer">
              <img className="sm:w-40 w-32 max-w-full cursor-pointer" src="https://staging-publicstorage1123538a-1p8k71zpb6h3c.s3.eu-west-3.amazonaws.com/Mobile+app+store+badge.svg" alt="App Store" width={160} height={10000}/>
            </a>
          </div>
          <img width={280} height={558} className="lg:mt-24 mt-16 lg:block hidden" src="https://staging-publicstorage1123538a-1p8k71zpb6h3c.s3.eu-west-3.amazonaws.com/payment.svg" alt=""/>
        </div>
      </div>
      {/* // small screen banner */}
      <div className='fixed flex gap-1 justify-between items-center bottom-0 w-full pt-6 pb-5 min-[500px]:px-10 min-[320px]:px-5 sm:hidden text-white rounded-t-3xl z-20' style={{background: '#0A3266'}}>
        <div>  
          <p className='text-3xl mb-4'>{t('Receive now .. Pay later!')}</p>
          <a target='_blank' rel="noopener noreferrer" href='https://onelink.to/myblu1' className='bg-blue-600 py-2 px-4 rounded-md text-white font-semibold text-sm block w-fit'>{t('Download myBlu Now')}</a>
          <div className='flex items-center text-sm mt-2'>
            <img width={68} height={13} src="https://staging-publicstorage1123538a-1p8k71zpb6h3c.s3.eu-west-3.amazonaws.com/Group+18698.svg" alt=""/> 
            4.8
          </div>
        </div>
        { i18n.language === 'en' ?
          <img width={144} height={199} style={{ marginTop:'-50px' }} src="https://staging-publicstorage1123538a-1p8k71zpb6h3c.s3.eu-west-3.amazonaws.com/secure+1.svg" alt=""/>
            :
          <img width={144} height={199} style={{ marginTop:'-50px' }} src="https://staging-publicstorage1123538a-1p8k71zpb6h3c.s3.eu-west-3.amazonaws.com/valu-ar.svg" alt=""/>
          }
      </div>
    </div>
  );
};
export default Banner;
