import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useEffect, useState } from 'react';

export default function Header() {
  const { t, i18n } = useTranslation();
  const {lang} = useParams()
  const navigate = useNavigate();
  const [dropDown, setDropDown] = useState(false);
  const [currentLang, setCurrentLang] = useState('');

  useEffect(() => {
    const initialLang = window.location.pathname.split('/')[1] === 'ar' ? 'ar' : 'en';
    setCurrentLang(initialLang);
    changeLanguage(initialLang);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const changeLanguage = (locale) => {
    document.body.style.direction = locale === 'en' ? 'ltr' : 'rtl';
    i18n.changeLanguage(locale);
    localStorage.setItem('lang', locale);
    setCurrentLang(locale);
    const currentPath = window.location.pathname.split('/').slice(2).join('/');
    navigate(`/${locale}/${currentPath}${window.location.search && window.location.search}`);
    setDropDown(false)
  };

  return (
    <div className="fixed left-0 top-0 w-full shadow-lg z-50">
      <div className="flex items-center justify-between w-full py-4 md:px-10 px-4 h-20 bg-white mx-auto" style={{ maxWidth: '1440px' }}>
        <img
          className="sm:w-48 w-28 shrink-0"
          src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/logo.png"
          alt="ShipBlu Logo"
          width={192}
          height={46}
          priority
        />
        <div className="flex items-center md:gap-6 gap-4">
          <a className="text-blue sm:text-base text-sm font-semibold" href="https://support.shipblu.com/" target="_blank" rel="noopener noreferrer">
            {t('Support ')}
          </a>
          <div className="relative w-76">
            <div className="flex items-center md:gap-3 cursor-pointer" onClick={() => setDropDown(!dropDown)}>
              <p className="truncate w-full sm:text-base text-sm font-semibold">
                {currentLang === 'en' ? 'English' : 'العربية'}
              </p>
              <div className="text-right flex items-center">
                {dropDown ? <ExpandLessIcon className="w-full" /> : <ExpandMoreIcon className="w-full" />}
              </div>
            </div>
            {dropDown && (
              <div className="absolute cursor-pointer left-0 bg-white border-solid border shadow-xl p-2 rounded-md" style={{ top: '40px' }}>
                <span onClick={() => changeLanguage(currentLang === 'en' ? 'ar' : 'en')} className="font-semibold">
                  {currentLang === 'en' ? 'العربية' : 'English'}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
