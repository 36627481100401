import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../index.css';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';  
import axios from '../axios';
import common from '../common';
import Header from '../components/Header';
import Banner from '../components/Banner';
import Essentials from '../components/Essentials';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';

export default function Home() {
  const navigate = useNavigate(); 
  const { t } = useTranslation();
  const [tracking, setTracking] = useState('');
  const [stopLoading, setStopLoading] = useState(true);
  const [notify, setNotify] = useState(false);
  const [message, setMessage] = useState('');
  const [lang] = useState(localStorage.getItem('lang') || 'en');

  useEffect(() => {
    document.body.style.direction = lang === 'ar' ? 'rtl' : 'ltr';
  }, [lang]);
  const trackOrder = () => {
    if (tracking !== ''){
      let trackingType = '';
      setStopLoading(false);
      setNotify(false);
      if (tracking[0] === '1' || (tracking[0] === '3' && tracking[1] === '1')) {
        trackingType = 'delivery-order';
      } else if (tracking[0] === '2' || (tracking[0] === '3' && tracking[1] === '2')) {
        trackingType = 'return';
      } else if (tracking[0] === '4' || tracking[0] === '4') {
        trackingType = 'cash-collection';
      }
      axios.get(`${trackingType}/${tracking}/`)
        .then(() => {
          navigate(`/${lang}/${tracking}`); 
        })
        .catch((error) => {
          setStopLoading(true)
          setNotify(true)
          setMessage(common.notifyResponseError(error))
        });
    }
  };

  return (
    <div className={i18n.language === 'ar' ? 'rtl mx-auto' : 'ltr mx-auto'}>
      <div className="w-full mx-auto" style={{ maxWidth: '1440px' }}>
        <div className={stopLoading ? '' : 'show-modal loading-window fixed inset-0 z-20'}>
          <div id="loading"></div>
        </div>
        {
          notify && 
          <div className="alert error mx-5">
            <p className="inner">{ message }</p>
          </div>
        }
        <Header />
        <Banner />
        <div className="sm:px-10 px-5 py-10 sm:mt-0 mt-20 lg:mb-6 sm:mb-2 mb-0 text-center">
          <div className="sm:text-2xl text-xl mb-5 font-semibold">
            <span className="text-black">{t('Track your shipment with') + ' '}</span>
            <span className="text-blue-900">{t('Ship')}</span>
            <span className="text-blue-600">{t('Blu')}</span>
          </div>
          <div className={`relative border h-12 border-gray-600 rounded-xl lg:w-1/2 sm:w-3/4 w-full m-auto py-2 ${i18n.language === 'en' ? 'sm:pl-3 pl-2 sm:pr-32 pr-20' : 'sm:pr-3 pr-2 sm:pl-32 pl-20'}`}>
            <input 
              style={{ marginTop: '2px' }} 
              className="outline-none bg-inherit w-full" 
              value={tracking} 
              onChange={(e) => setTracking(e.target.value)} 
              onKeyDown={(e) => { 
                if (e.key === "Enter" && e.target.value !== '') 
                  trackOrder();
              }} 
              type="text" 
              placeholder={t('Enter your shipment tracking number')} 
            />
            <button 
              style={{ marginTop: '3px' }} 
              className={`absolute top-0 mx-1 bg-blue-600 text-white rounded-lg focus:outline-none sm:px-8 h-10 ${i18n.language === 'en' ? 'right-0 px-4' : 'left-0 px-6'}`} 
              onClick={trackOrder}>
              {t('Track')}
            </button>
          </div>
        </div>
        <Essentials />
        <ContactUs />
      </div>
      <Footer />
    </div>
  );
}