/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import WarningAmber from '@mui/icons-material/WarningAmber'

export default function DisclaimerModal ({ showDisclaimerModal, sendDataToParentDisclamer}) {
  const { t } = useTranslation()
  const [modal] = useState(false)

  const closeDisclaimerModal = (remove) => {
    if (remove) {
      sendDataToParentDisclamer(modal, 'removeBlur')
    } else {
      sendDataToParentDisclamer(modal)
    }
  }
  
return (
  <div className={`modal-window fixed inset-0 z-[100] ${showDisclaimerModal ? 'show-modal' : ''}`}>
    <div className="rounded-xl sm:w-[445px] w-4/5 absolute sm:p-6 py-6 px-4 text-center">
      <a onClick={() => closeDisclaimerModal()} className="modal-close">&times;</a>
      <div className="w-full sm:grid justify-items-center block">
        <WarningAmber className='material-icons-round text-red-500'>{'warning_amber'}</WarningAmber>
        <p className="text-red-500 font-medium text-xl"> { t('Disclamer') } </p>
        <p className="text-[#0D0D0D] text-sm w-3/4 mt-4 m-auto">
          { t('Please don\'t share the QR code with the delivery agent before his arrival.')}
        </p>
        <p className="text-[#404040] text-sm mt-4 m-auto">
          { t('The delivery agent will scan the QR code directly from your device upon arrival.Sharing the QR code with anyone is under your responsibility.')}
        </p>
      </div>
      <div className="flex items-center gap-3 mt-8">
        <button className="rounded-md bg-[#8599B3] text-white text-sm h-8 w-1/2" onClick={() => closeDisclaimerModal()}>{ t('Cancel') }</button>
        <button className="bg-blue-600 text-white rounded-md h-8 text-sm w-1/2" onClick={() => closeDisclaimerModal('remove')}>{ t('I understand') }</button>
      </div>
    </div>
  </div>
  )
}