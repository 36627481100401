import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import i18n from '../i18n'

class Essentials extends Component {
  constructor () {
    super();
    this.state = {
      essentials: [
        {
          icon: 'https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/location1.svg',
          title: 'Instant',
          subTitle: 'Tracking Updates',
          content: 'Stay in the loop with real-time updates on your order\'s shipping progress, right at your fingertips.'
        },
        {
          icon: 'https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/visa.svg',
          title: 'Card',
          subTitle: 'Payment on Delivery',
          content: 'Conveniently pay for your order upon delivery using your preferred card, hassle-free.'
        },
        {
          icon: 'https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/calendar.svg',
          title: 'Edit',
          subTitle: 'Preferred Delivery Date',
          content: 'Seamlessly adjust the delivery date to fit your schedule, giving you control over when your order arrives.'
        },
        {
          icon: 'https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/edit.svg',
          title: 'Edit',
          subTitle: 'Delivery Location',
          content: 'Have the flexibility to edit the delivery location to ensure your order reaches you where it\'s most convenient.'
        },
        {
          icon: 'https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/chat.svg',
          title: 'Contacting',
          subTitle: 'Support',
          content: 'Easily reach out to customer support for any inquiries or assistance regarding your order.'
        },
        {
          icon: 'https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/notification.svg',
          title: 'Personalized',
          subTitle: 'Notifications',
          content: 'Receive customized alerts and notifications tailored to your specific order, keeping you informed every step of the way.'
        },
      ]
    }
  }
  render () {
    const { t } = this.props;
    return (
      <div>
        <div className='text-center px-4 pt-12 font-semibold sm:text-2xl text-xl'> 
            <span className="pr-2 text-gray-950">{t('Track, Pay, and Edit:')}</span>
            <span className='pr-2 text-blue-600'>{t('Essentials')}</span>
            <span className='text-gray-950'>{t('for Your Order Management')}</span>
        </div>
        <div  className='grid grid-cols-1 xl:grid-cols-3 md:grid-cols-2 gap-5 py-16 lg:px-24 md:px-10 px-6'>
          {
            this.state.essentials.map((item, index) => (
              <div key={index} className='p-12 border border-dashed rounded-3xl border-blue-600'>
                <img className={`w-auto ${i18n.language === 'ar' ? 'switch' : ''}`} src={item.icon} width={8} height={8} alt=""/>
                <p className='text-2xl mb-1 text-blue-900 font-semibold'>{t(item.title)}</p>
                <p className='text-2xl mb-2 text-blue-900 font-semibold'>{t(item.subTitle)}</p>
                <p className='text-lg text-gray-900'>{t(item.content)}</p>
              </div>
            ))
          }
        </div>
      </div>
    )
  }
}
export default withTranslation()(Essentials)
