/* eslint-disable jsx-a11y/anchor-is-valid */
import common  from '../common'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ChangeOrderDetailsModal({preferenceModal, sendDataToParent, preferenceModalType, times, deliveryWindowsDic, preferredDate, confirmChangData}) {
  const [modal] = useState(false);
  const [changed, setChanged] = useState(false);
  const { t } = useTranslation();

  const closePreferenceModal = () => {
    setChanged(false)
    sendDataToParent(modal);
  }
  const changeDate = () => {
    setChanged(true)
    confirmChangData(preferredDate)
  }
  
  return (
    <div className={`modal-window fixed inset-0 z-[100] ${preferenceModal ? 'show-modal' : ''}`}>
      <div className="rounded-lg sm:w-1/4 w-4/5 absolute p-6">
        <a onClick={closePreferenceModal} className="modal-close">&times;</a>
       {
        changed ?
          <div className="w-full grid justify-items-center">
            <img src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/hands.svg" className="sm:w-20 w-12 m-auto" alt="Hands" width={80} height={0} />
            <p className="text-base text-darkblue mt-4 text-center">
              { preferenceModalType === 'times' ? t('You\'ve rescheduled your shipment\'s delivery window to:') : t('You\'ve rescheduled your delivery date to:') }
            </p>
            <div className="mt-4 flex modal-subtitle">
              <span className="dot self-center"></span>
              <hr className="self-center hr" />
                {
                  preferenceModalType === 'times' ?
                  <p className="text-align text-gray font-normal text-sm px-2">
                    { times.length !== 0 ? t('Between') + ' ' : t('No delivery window chosen') }
                    {
                      times.map((item, index) => (
                        <span key={index} className="text-blue font-medium">{ deliveryWindowsDic[4].split('-').join(t('And')) }
                        {
                          index < times.length - 1 && <span className="text-gray">{ 'or' }</span>
                        }
                        </span>
                      ))
                    }
                  </p> : 
                  <p className="text-align text-blue font-medium text-sm px-2">{ common.gettingDate(preferredDate) }</p>
                }
              <hr className="self-center hr visible" />
              <span className="dot self-center visible"></span>
            </div>
          </div>
          :
          <div>
            <div className="w-full grid justify-items-center">
              <div className="w-full sm:grid justify-items-center block">
                <p className="text-darkblue p-2 font-semibold"> { t('Reschedule Delivery Date') } </p>
                <img src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/change-order.gif" alt="Calendar gif" width={280} height={175}/>
                <div className="font-semibold text-gray md:text-sm sm:text-sm m-auto text-center">
                  <p className="mt-5">{ t('You will change the date of your order to')}</p>
                  {
                  preferenceModalType === 'times' ?
                  <span className="text-align text-gray font-normal text-sm px-2">
                    { times.length !== 0 ? t('Between') + ' ' : t('No delivery window chosen') }
                    {
                      times.map((item, index) => (
                        <span key={index} className="text-blue font-medium">{ deliveryWindowsDic[4].split('-').join(t('And')) }
                        {
                          index < times.length - 1 && <span className="text-gray">{ 'or' }</span>
                        }
                        </span>
                      ))
                    }
                  </span> : 
                  <span className="text-align text-blue font-medium text-sm px-1">{ common.gettingDate(preferredDate) }</span>
                }
                </div>
              </div>
              <div className='flex mt-5'>
                <button className="button-second  px-3 rounded-lg font-semibold text-sm h-10" onClick={closePreferenceModal}>{ t('Cancel') }</button>
                <button className="bg-blue-600 text-white px-3 mx-3 rounded-lg font-semibold text-sm h-10" onClick={changeDate}>{ t('Yes, Reschedule') }</button>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}