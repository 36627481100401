import React, {useState} from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Home from './Pages/Home';
import TrackingNumber from './Pages/TrackingNumber'
import ScrollToTop from "./components/ScrollToTop";
import Wallet from './Pages/Wallet';

function App() {
  const [lang] = useState(localStorage.getItem('lang'))

  return (
    <BrowserRouter>
    <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Navigate to={lang ? `/${lang}` : '/en'} replace />} />
        <Route path="/:lang" element={<Home />} />
        <Route path="/:lang/:trackingNumber" element={<TrackingNumber />} />
        <Route path="/:lang/payments/wallet" element={<Wallet />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
