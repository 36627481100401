/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ChangeOrderDetailsModal({order, pinModal, sendPinModalToParent}) {
  const [modal] = useState(false);
  const { t } = useTranslation();

  const closeChangeOrder = () => {
    sendPinModalToParent(modal);
  }

  return (
    <div className={`modal-window fixed inset-0 z-[100] ${pinModal ? 'show-modal' : ''}`}>
      <div className="rounded-lg sm:w-2/5 w-4/5 absolute p-6 text-center">
        <a onClick={closeChangeOrder} className="modal-close">&times;</a>
        <div className="w-full sm:grid justify-items-center block">
          <img src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/change-order.gif" alt="Calendar gif" width={280} height={175}/>
          <p className="text-darkblue p-2 font-semibold"> { t('Change order details') } </p>
          <p className="font-semibold text-gray md:text-sm sm:text-sm w-3/4 m-auto">
            { t('You can change the details of your order through the link sent to your WhatsApp number , or by contacting the shipper') + ' '}
            <span className="text-blue">“{ order.merchant.name }”</span>
          </p>
        </div>
        <button className="button-enable py-3 mt-3 px-12 rounded-lg font-semibold text-base outline-none" onClick={closeChangeOrder}>{ t('Got It') }</button>
      </div>
    </div>
  )
}