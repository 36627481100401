/* eslint-disable no-useless-constructor */
import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import OpenInNew from '@mui/icons-material/OpenInNew';

class Footer extends Component {
  constructor () {
    super();
  }
  render () {
    const { t } = this.props;
    return (
      <footer className="sm:mb-0 mb-[172px] py-12 lg:px-16 md:px-10 px-6 text-white" style={{maxwidth: 295 + 'px', backgroundColor: '#0A3266' }}>
        <div className='flex gap-4 flex-wrap mx-auto' style={{maxWidth: 1440 + 'px'}} >
          <div className='flex-grow'>
            <img className='sm:w-2/3 w-auto' width={14} height={14} style={{maxwidth: 295 + 'px'}} alt='' src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/logo-white.svg"/>
            <div className='my-10 text-lg text-white'>
              <p className='sm:whitespace-nowrap'>{t(`© Copyright ${new Date().getFullYear()} - ShipBlu Technology.`)}</p>
              <p>{t('All Rights Reserved.')}</p>
              <p>{t('4 Nile Street, Giza, Egypt.')}</p>
            </div>
            <div className="flex gap-4 items-center mt-1">
              <a href='https://www.linkedin.com/company/shipblu/' target='_blank' rel="noreferrer">
                <img className="cursor-pointer" width={22} height={22} style={{maxwidth: 295 + 'px'}} alt='' src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/linkedin.svg"/>
              </a>
              <a href='https://www.instagram.com/shipblu/' target='_blank' rel="noreferrer">
                <img width={22} height={22} style={{maxwidth: 295 + 'px'}} alt='' src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/instagram.svg"/>
              </a>
              <a href='https://www.facebook.com/shipblu/' target='_blank' rel="noreferrer">
                <img width={22} height={22} style={{maxwidth: 295 + 'px'}} alt='' src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/facebook.svg"/>
              </a>
              <a href='https://twitter.com/_shipblu/' target='_blank' rel="noreferrer">
                <img width={22} height={22} style={{maxwidth: 295 + 'px'}} alt='' src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/twitter.svg"/>
              </a>
            </div>
          </div>
          <div className='flex-grow'>
            <p className="font-bold text-xl mt-2" style={{color: '#FFBF80'}}>{t('Company')}</p>
            <p className="mt-4 text-white text-lg cursor-pointer"><a href="https://shipblu.com" target='_blank' rel="noreferrer">{t('Home')}</a></p>
            <p className="mt-1 text-white text-lg cursor-pointer"><a href="https://play.google.com/store/apps/details?id=com.shipblu.myblu" target='_blank' rel="noreferrer">{t('myBlu App')}</a></p>
            <p className="mt-1 text-white text-lg cursor-pointer"><a href="https://www.youtube.com/watch?v=q5PMbAUwKv4&list=PLEG0i0BCv0rvkh1rvJeQF-Tgv4DeF9jHT" target='_blank' rel="noreferrer">{t('BluCast')}</a></p>
            <p className="mt-1 text-white text-lg cursor-pointer"><a href="https://support.shipblu.com/" target='_blank' rel="noreferrer">{t('Support')}</a></p>
          </div>
          <div className='flex-grow'>
            <p className="font-bold text-xl mt-2"style={{color: '#FFBF80'}}>{t('Legal')}</p>
            <p className="mt-4 text-white text-lg cursor-pointer"><a href="https://shipblu.com/ar/%d8%a7%d9%84%d8%b4%d8%b1%d9%88%d8%b7-%d9%88%d8%a7%d9%84%d8%a3%d8%ad%d9%83%d8%a7%d9%85/" target='_blank' rel="noreferrer">{t('Terms & Conditions')}</a></p>
            <p className="mt-1 text-white text-lg cursor-pointer"><a href="https://shipblu.com/privacy-policy/" target='_blank' rel="noreferrer">{t('Privacy Policy')}</a></p>
          </div>
          <div className='flex-grow'>
            <p className="font-bold text-xl mt-2" style={{color: '#FFBF80'}}>{t('Contact Us')}</p>
            <p className="mt-4 text-white font-bold text-lg">{t('For sales inquiries')}</p>
            <p className="mt-1 cursor-pointer flex text-white">
              <a className="flex" href="https://shipblu.com/get-started-with-shipblu/" target='_blank' rel="noreferrer">
                <span className="underline">{t('Enterprise Price Plans')}</span>
                <OpenInNew className="material-icons">{'open_in_new'}</OpenInNew>
              </a>
            </p>
            <p className="mt-1 text-white font-bold text-lg">{t('For customer service')}</p>
            <p className="mt-1 cursor-pointer flex text-white"><a href="mailto:help@shipblu.com">{'help@shipblu.com'}</a></p>
          </div>
        </div>
      </footer>
    )
  }
}
export default withTranslation()(Footer)