/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { useTranslation } from 'react-i18next';

export default function AddressModal({addressModal, sendDataToParent}) {
  const [modal] = useState(false);
  const { t } = useTranslation();
  const closeAddressModal = () => {
    sendDataToParent(modal);
  }

  return (
    <div className={`modal-window fixed inset-0 z-[100] ${addressModal ? 'show-modal' : ''}`}>
      <div className="rounded-lg sm:w-2/5 w-4/5 absolute p-6">
        <a onClick={closeAddressModal} className="modal-close">&times;</a>
        <div className="w-full text-center">
          <img src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/location.svg" className="sm:w-20 w-12 m-auto" alt="Location Icon" width={80} height={80} />
          <p className="text-base text-darkblue mt-4 self-center tracking-wider">{ t('Your location is now updated') + '!' }</p>
        </div>
      </div>
    </div>
  )
}