import common  from '../common';
import axios from '../axios';
import PreferenceModal from "./PreferenceModal.jsx"
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChangeOrderDetailsModal from "./ChangeOrderDetailsModal.jsx"

export default function Preference({order, showQrCode, times, trackingType, pin, loadOrder}) {
  const { t } = useTranslation();
  const createdStatuses = ['created', 'pickup_requested', 'return_requested', 'out_for_pickup', 'out_for_delivery']
  const deliveredStatues = ['delivered']
  const deliveryWindowsDic = {1: '10am - 1pm', 2: '1pm - 4pm', 3: '4pm - 7pm', 4: '10am - 7pm'}
  const [preferenceModalType, setType] = useState('')
  const [pinModal, setPinModal] = useState(false)
  const [preferredDate, setPreferredDate] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [dataFromChild, setDataFromChild] = useState(null);
  const [preferenceModal, setPreferenceModal] = useState(false);
  const getDataFromChild = (preferenceModal) => {
    setPreferenceModal(false);
    setDataFromChild(preferenceModal);
  };
  const getPinModal = (pinModal) => {
    setPinModal(pinModal);
  };
  const confirmChangData = (preferredDate) => {
    let data = {}
    data = {
      preferred_date: preferredDate,
      preferred_window: []
    }
    axios.post(`${trackingType}/${order.tracking_number}/?pin=${pin ? pin : ''}`, data, {
    })
      .then(() => {
        loadOrder()
        setPreferenceModal(true)
        setPreferredDate(data.preferred_date)
      })
      .catch((error) => {
      })
  }
  const onChangeValue = (event) => {
    if (pin && order.packages) {
      setPreferenceModal(true)
      let data = {}
        switch (event.target.value.length) {
          case 1:
            data = {
              preferred_window: order.preferred_window.length > 0 ? [] : [event.target.value]
            }
            setType('times')
            break;
          default:
            data = {
              preferred_date: event.target.value,
              preferred_window: []
            }
            setType('date')
            break;
        }
      setPreferredDate(data.preferred_date)
    } else {
      setPinModal(true)
    }
  }

  return (
    <div>
      <div className="card xl:py-7 xl:px-14 p-6">
        <p className="text-[#404040] font-medium text-xl">{ t('Reschedule Delivery Date') }</p>
        {
          !createdStatuses.includes(order.status) && !deliveredStatues.includes(order.status)? 
            <div className='grid lg:grid-cols-5 grid-cols-1 lg:gap-8 gap-2 sm:my-10 my-4'>
              {
                order.visit_dates.map((date) => (
                  <label key={date} htmlFor={date} className="cursor-pointer h-full m-auto w-full">
                    <input onClick={onChangeValue} id={date} className={date === order.preferred_date ? 'checked' : ''} value={date} type="radio" name="select"/>
                    {
                      order.visit_dates !== null && 
                      <p className="text-center text-medium font-semibold p-4">{ common.gettingDate(date) }</p>
                    }
                  </label>
                ))
              }
            </div>
           : 
          <div className="mt-4 flex gap-1 items-center mx-4">
            <img className="pt-0.5" src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/light.svg" alt="Light Icon" width={18} height={18} />
            <span className="text-gray text-sm">{ deliveredStatues.includes(order.status) ?  t('The order was already delivered') : t('This information will appear once the order is picked up but before it is out for delivery.')}</span>
          </div>
        }
      </div>
      <PreferenceModal preferenceModal={preferenceModal} preferredDate={preferredDate} sendDataToParent={getDataFromChild} preferenceModalType={preferenceModalType} deliveryWindowsDic={deliveryWindowsDic} order={order} times={times} confirmChangData={confirmChangData}/>
      <ChangeOrderDetailsModal order={order} pinModal={pinModal} sendPinModalToParent={getPinModal}/>
    </div>
  )
}