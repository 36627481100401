
import React from 'react';
import axios from '../axios';
import AddressModal from "./AddressModal.jsx"
import { withTranslation } from 'react-i18next';
import ChangeOrderDetailsModal from "./ChangeOrderDetailsModal.jsx"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import i18n from '../i18n';
import common from '../common';
class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pinModal: false,
      addressModal: false,
      search: '',
      editAddress: false,
      dropDownMenuGov: false,
      dropDownMenuCity: false,
      dropDownMenuZone: false,
      governorates: [],
      cities: [],
      zones: [],
      line1: '',
      line2: '',
      governorate: {
        id: '',
        name: ''
      },
      city: {
        id: '',
        name: ''
      },
      zone: {
        id: '',
        name: ''
      }
    };
  }
  getPinModal = (pinModal) => {
    this.setState({
      pinModal: pinModal
    });
  };
  getDataFromChild = (addressModal) => {
    this.setState({addressModal: false})
  };
  handleChange = (e) => {
    this.setState({ search: e.target.value });
  };
  updateLocation (location) {
    const current = {
      lat: parseFloat(location.lat),
      lng: parseFloat(location.lng)
    }
    this.props.sendDataToParent(current);
  }
  searchAddress = async (event) => {
    if (event.key === 'Enter') {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${this.state.search}&key=AIzaSyBcCT_Pk_-o9wQJKAc2fhxIMJNsJE8Z_Zs`);
      if (response.status === 200) {
        const data = await response.json()
        if (data.results.length > 0) {
          this.updateLocation(data.results[0].geometry.location)
          this.setState({
            search: ''
          })
        }
      } else {
      }
    }
  }
  sendData = () => {
    if (this.props.pin) {
      const data = {
        latitude: this.props.current.lat,
        longitude: this.props.current.lng
      }
      axios.post(`${this.props.trackingType}/${this.props.order.tracking_number}/?pin=${this.props.pin ? this.props.pin : ''}`, data, {
      })
        .then(() => {
          this.setState({addressModal: true})
        })
        .catch((error) => {
          this.stopLoading = true
          this.notify = true
          this.message = common.notifyResponseError(error)
        })
    } else {
      this.setState({pinModal: true})
    }
  }
  showPosition = (position) => {
    const current = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    }
    this.props.sendDataToParent(current);
  }
  showError = (error) => {
    switch (error.code) {
    case 1:
        this.$emit('showErrorMessage')
        this.message = 'Please, allow location services on your device!'
        this.$emit('message', this.message)
        break
    default:
        break; 
      }
    
  }
  updateCoordiates = (position) => {
    const current = {
      lat: position.latLng.lat(),
      lng: position.latLng.lng()
    }
    this.props.sendDataToParent(current);
  }
  activateAddressEdit = () => {
    this.loadGovernorates()
    this.setState({
      line1: this.props.order.customer.address.line_1,
      line2: this.props.order.customer.address.line_2,
      governorate: {
        id: this.props.order.customer.address.zone.city.governorate.id,
        name: this.props.order.customer.address.zone.city.governorate.name
      },
      city: {
        id: this.props.order.customer.address.zone.city.id,
        name: this.props.order.customer.address.zone.city.name
      },
      zone: {
        id: this.props.order.customer.address.zone.id,
        name: this.props.order.customer.address.zone.name
      }
    })
    this.setState({
      editAddress: true
    })
  }
  loadGovernorates () {
    axios.get(`magic/geo/governorates/`)
    .then((response) => {
      const uncoveredIDs = [11, 12, 20, 21, 22, 23]
      this.setState({
        governorates: response.data.filter(item => !uncoveredIDs.includes(item.id) )
      })
      this.loadCities(this.props.order.customer.address.zone.city.governorate)
    })
    .catch((error) => {
      this.stopLoading = true
      this.notify = true
    })
  }
  loadCities (governorate) {
    const url = 'magic/geo/governorates/'
    const type = '/cities/'
    axios.get(`${url}${governorate.id}${type}`)
    .then((response) => {
      this.loadZones(this.props.order.customer.address.zone.city)
      this.setState({
        governorate: {
          id: governorate.id,
          name: governorate.name
        },
        cities: response.data,
        dropDownMenuGov: false
      })
    })
    .catch((error) => {
      this.stopLoading = true
      this.notify = true
    })
  }
  loadZones (city) {
    const url = 'magic/geo/cities/'
    const type = '/zones/'
    axios.get(`${url}${city.id}${type}`)
    .then((response) => {
      this.setState({
        city: {
          id: city.id,
          name: city.name
        },
        zones: response.data,
        dropDownMenuCity: false
      })
    })
    .catch((error) => {
      this.stopLoading = true
      this.notify = true
    })
  }
  updateAddress = () => {
    const data = {
      customer: {
        id: this.props.order.customer.id,
        address: {
          id: this.props.order.customer.address.id,
          zone: this.state.zone.id,
          line_1: this.state.line1,
          line_2: this.state.line2
        }
      }
    }
    if (this.props.pin){
      axios.post(`${this.props.trackingType}/${this.props.order.tracking_number}/?pin=${this.props.pin ? this.props.pin : ''}`, data, {
      })
        .then((response) => {
          this.props.loadOrder()
          this.setState({
            line1: response.data.customer.address.line_1,
          })
          this.setState({addressModal: true, editAddress: false})
        })
        .catch((error) => {
          this.stopLoading = true
          this.notify = true
          this.message = common.notifyResponseError(error)
        })
    } else {
      this.setState({pinModal: true})
    }
  }

  render () {
    const { t } = this.props;
    const editAddress = this.state.editAddress;
    let button;
    if (editAddress) {
      button = <button onClick={this.updateAddress} className="rounded-md bg-blue-600 text-white px-6 font-semibold outline-none h-10">{ t('Confirm Changes') }</button>
    } else if (!editAddress && this.props.order.packages) {
      button = <button onClick={this.activateAddressEdit} className="rounded-md bg-blue-600 text-white px-6 font-semibold outline-none h-10">{ t('Edit Delivery Address') }</button>
    }
    return (
      this.props.current.lat ? 
      <div className="w-full h-full card xl:py-7 xl:px-14 p-6 relative">
        <p className="text-[#404040] font-base text-xl flex">{ t('Packages') }</p>
        {
          this.state.editAddress === true ?             
          <div className='mt-6'>
            {
              this.props.order.packages && 
              <div className='flex gap-2'>
                <p className='text-[#404040]'>{t('Address')}: </p>
                <input className={`focus:outline-none border-b-2 w-full`} value={this.state.line1} onChange={(event) => this.setState({line1: event.target.value})}></input>
              </div>
            }
            <div>
              <div className='flex mt-3 gap-2 items-center'>
                <p className='text-[#404040]'>{t('Governorate')}: </p>
                <div className="relative w-full">
                  <div className="w-full flex items-center gap-3 justify-between cursor-pointer whitespace-no-wrap overflow-hidden" onClick={()=> this.setState({dropDownMenuGov: !this.state.dropDownMenuGov, dropDownMenuZone: false, dropDownMenuCity: false})}>
                    <p className="truncate w-full sm:text-base text-sm">{this.state.governorate.name}</p>
                    <div className="text-right flex items-center">
                      {
                        this.state.dropDownMenuGov === true ? <ExpandLessIcon className="material-icons w-full">{'expand_less'}</ExpandLessIcon> : <ExpandMoreIcon className="material-icons w-full">{'expand_more'}</ExpandMoreIcon>
                      }
                    </div>
                  </div>
                    {
                      this.state.dropDownMenuGov === true && 
                      <div className={`absolute w-full cursor-pointer bg-white border-solid border shadow-xl p-2 h-44 overflow-y-scroll rounded-md z-50 ${i18n.language === 'ar' ? 'left-0': 'right-0'}`}>
                        {
                          this.state.governorates.map((item,index) => (
                            <div key={index} className='hover:bg-blue-600 hover:text-white' >
                              <span onClick={() => this.loadCities(item)} className="font-medium">{item.name}</span>
                            </div>
                        ))
                        }
                      </div>
                    }
                </div>
              </div>

              <div className='flex mt-3 gap-2 items-center'>
                <p className='text-[#404040]'>{t('city')}: </p>
                <div className="relative w-full">
                  <div className="w-full flex items-center gap-3 cursor-pointer whitespace-no-wrap overflow-hidden" onClick={()=> this.setState({dropDownMenuCity: !this.state.dropDownMenuCity, dropDownMenuGov: false, dropDownMenuZone: false})}>
                    <p className="truncate w-full sm:text-base text-sm">{this.state.city.name}</p>
                    <div className="text-right flex items-center">
                      {
                        this.state.dropDownMenuCity ? <ExpandLessIcon className="material-icons w-full">{'expand_less'}</ExpandLessIcon> : <ExpandMoreIcon className="material-icons w-full">{'expand_more'}</ExpandMoreIcon>
                      }
                    </div>
                  </div>
                  {
                      this.state.dropDownMenuCity === true && 
                      <div className={`absolute w-full cursor-pointer bg-white border-solid border shadow-xl p-2 rounded-md z-50 h-44 overflow-y-scroll ${i18n.language === 'ar' ? 'left-0': 'right-0'}`}>
                        {
                          this.state.cities.map((item,index) => (
                            <div key={index} className='hover:bg-blue-600 hover:text-white' >
                              <span onClick={() => this.loadZones(item)} className="font-medium">{item.name}</span>
                            </div>
                        ))
                        }
                      </div>
                    }
                </div>
              </div>
              
              {
                this.props.order.packages &&
                <div className='flex mt-3 gap-2 items-center'>
                  <p className='text-[#404040]'>{t('Zone')}: </p>
                  <div className="relative w-full">
                    <div className="w-full flex items-center md:gap-3 cursor-pointer whitespace-no-wrap overflow-hidden" onClick={()=> this.setState({dropDownMenuZone: !this.state.dropDownMenuZone, dropDownMenuGov: false, dropDownMenuCity: false})}>
                      <p className="truncate w-full sm:text-base text-sm">{this.state.zone.name}</p>
                      <div className="text-right flex items-center">
                        {
                          this.state.dropDownMenuZone ? <ExpandLessIcon className="material-icons w-full">{'expand_less'}</ExpandLessIcon> : <ExpandMoreIcon className="material-icons w-full">{'expand_more'}</ExpandMoreIcon>
                        }
                      </div>
                    </div>
                      {
                        this.state.dropDownMenuZone === true && 
                        <div className={`absolute w-full cursor-pointer bg-white border-solid border shadow-xl p-2 h-44 overflow-y-scroll rounded-md z-50 ${i18n.language === 'ar' ? 'left-0': 'right-0'}`}>
                          {
                            this.state.zones.map((item,index) => (
                              <div className='mb-1 hover:bg-blue-600 hover:text-white p-1' key={index}>
                                <span onClick={() => this.setState({zone: {id: item.id, name: item.name}, dropDownMenuZone: false})} className="font-medium">{item.name}</span>
                              </div>
                          ))
                          }
                        </div>
                      }
                  </div>
                </div>
              }
            </div>

            <div className='flex mt-3 mb-6 gap-2'>
              <p className='text-[#404040]'>{t('Landmark')}: </p>
              <input className={`focus:outline-none border-b-2  w-full`} value={this.state.line2} onChange={(event) => this.setState({line2: event.target.value})}></input>
            </div>
            
            <div className={`${this.props.blur === false ? 'lg:mt-20 mt-6' : 'mt-6'} flex items-end justify-between gap-4`}>
              <div className="shrink-0">{button}</div>
              <img className={`sm:block hidden xl:w-64 lg:w-52 w-64 xl:-mx-14 -mx-6 ${i18n.language === 'ar' ? 'switch' : ''}`} src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/bike.svg" width={100} height={50} alt="" />
            </div>
          </div> 
          : 
          <div className='mt-6'>
            <div className={`flex ${this.props.order.packages ? 'flex-col' : 'flex-row'} flex-wrap gap-x-6 gap-y-3`}>
              {
                this.props.order.packages && 
                <div>
                  <span className='text-[#404040]'>{t('Address')}: </span>
                  <span className='text-gray-500'>{this.props.order.customer.address.line_1}</span>
                </div>
              }
              <div>
                <span className='text-[#404040]'>{t('Governorate')}: </span>
                <span className='text-gray-500'>{this.props.order.customer.address.zone.city.governorate.name}</span>
              </div>
              <div>
                <span className='text-[#404040]'>{t('city')}: </span>
                <span className='text-gray-500'>{this.props.order.customer.address.zone.city.name}</span>
              </div>
              {
                this.props.order.packages && 
                <div>
                  <span className='text-[#404040]'>{t('Zone')}: </span>
                  <span className='text-gray-500'>{this.props.order.customer.address.zone.name}</span>
                </div>
              }
             {
               this.props.order.packages && 
               <div>
                <span className='text-[#404040]'>{t('Landmark')}: </span>
                <span className='text-gray-500'>{this.props.order.customer.address.line_2}</span>
              </div>
             }
            </div>
            <div className={`${this.props.blur === false ? 'lg:mt-20 mt-6' : 'mt-6'} flex items-end justify-between gap-4`}>
              <div className="shrink-0">{button}</div>
              <img className={`sm:block hidden xl:w-64 lg:w-52 w-64 xl:-mx-14 -mx-6 ${i18n.language === 'ar' ? 'switch' : ''}`} src="https://production-publicstorage1123538a-1j4jxuuq2c9nf.s3.eu-west-3.amazonaws.com/customer-portal/bike.svg" width={100} height={50} alt="" />
            </div>
          </div>
        }
        <AddressModal sendDataToParent={this.getDataFromChild} addressModal={this.state.addressModal} />
        <ChangeOrderDetailsModal order={this.props.order} pinModal={this.state.pinModal} sendPinModalToParent={this.getPinModal} />
      </div> : null
    );
  }
};
export default withTranslation()(Map)